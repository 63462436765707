<template>
    <div class="gift_recharge_pop">
        <van-popup v-model="giftpayShow" :get-container="getContainer" @close="close" :lock-scroll="true" :closeOnClickOverlay='false' style="background:none">
            <div class="payPage">
                <div class="content">
                    <div class="payText">
                        <span class="title" v-html="getTitle(1)"></span>
                        <span class="payTip" v-html="getTitle(2)"></span>
                    </div>
                    </div>
                <div class="buttom">
                    <span class="btn cancel" @click="close">{{ $t('m.cancel') }}</span>
                    <span class="btn confirm" @click="confirm">{{ $t('m.confirm') }}</span> 
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>

export default({
    props: {
        giftpayShow: {
            type: Boolean,
            default: false
        },
        lang: {            
            type: Number,
            default: 1
        },
        //用户领取礼包状态  0： 未领取  1：已领取
        claimStatus: {            
            type: Number,
            default: 1
        },
    },
    data(){
        return {
            titleTexts: {
                title: '',  //金币
                tips: ''
            }
        }   
    },
    created(){
        console.log('changegiftpackPop.vue文件更换礼包弹窗...............', '用户礼包领取状态.........', this.claimStatus)
        // this.titleTexts.title = this.$t('m.hasbuyGiftpack')
        // this.titleTexts.tips = this.$t('m.hasbuyGiftpackdesc') + this.$t('m.hasbuyGiftpackdescred')       
        this.titleTexts.title = this.$t('m.text4-7')  //你已经领取了礼包
        this.titleTexts.tips = this.$t('m.hasClaimGiftpackdesc') + this.$t('m.hasClaimGiftpackdescred')       
    },
    methods: {    
        // 返回一个特定的 DOM 节点，作为挂载的父节点
        getContainer() {
            return document.querySelector('.Recharge');
        },
        getTitle(type){
            if(type === 1) {
                return this.titleTexts.title
            } else if(type === 2)
                return this.titleTexts.tips
        },    
        close() {
            this.$emit('closePop')
        },
        confirm() {
            //console.log('确认')
            this.close()
            this.$emit('confirmFn')
        }
    }
})
</script>